import request from '@/utils/request';

// 提交个人信息
export function commitUserInfo(data) {
  return request({
    url: '/user/commit',
    method: 'post',
    data,
  });
}

// 获取个人信息
export function getUserInfoById(params) {
  return request({
    url: '/user/getById',
    method: 'get',
    params,
  });
}

// 获取用户查询次数
export function getUserRemainingNumber(params) {
  return request({
    url: '/user/limit/getByUserId',
    method: 'get',
    params,
  });
}

export function editUserInfo(data) {
  return request({
    url: '/user/edit',
    method: 'post',
    data,
  });
}

export function getSchoolList() {
  return request({
    url: '/school/getList',
    method: 'get',
  });
}
