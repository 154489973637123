<template>
  <el-form
    :model="form"
    :rules="rules"
    label-width="100px"
    class="demo-ruleForm"
  >
    <p class="form-p">基本信息</p>
    <el-form-item label="考生姓名" prop="userName">
      <el-input v-model="form.userName"></el-input>
    </el-form-item>
    <el-form-item label="考生性别" prop="sex">
      <el-radio-group v-model="form.sex">
        <el-radio :label="0">男</el-radio>
        <el-radio :label="1">女</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="考生毕业院校">
      <el-input v-model="form.school"></el-input>
    </el-form-item>
    <el-form-item label="考生类型">
      <el-select v-model="form.studentType" placeholder="请选择类型">
        <el-option label="文化课考生" :value="1"></el-option>
        <el-option label="美术生" :value="2"></el-option>
        <el-option label="体育生" :value="3"></el-option>
        <el-option label="文化编导考生" :value="4"></el-option>
        <el-option label="书法类考生" :value="5"></el-option>
        <el-option label="舞蹈类考生" :value="6"></el-option>
        <el-option label="音乐生" :value="7"></el-option>
        <el-option label="播音主持类考生" :value="8"></el-option>
        <el-option label="影视戏剧表演生" :value="9"></el-option>
        <el-option label="服装表演类考生" :value="10"></el-option>
        <el-option label="航空服务艺术类考生" :value="11"></el-option>
        <el-option label="摄影类考生" :value="12"></el-option>
      </el-select>
    </el-form-item>
    <p class="form-p">考生成绩</p>
    <el-form-item label="文化课">
      <el-input v-model="form.scoreInfo.cultureGrade"></el-input>
    </el-form-item>
    <el-form-item label="语文">
      <el-input v-model="form.scoreInfo.languageGrade"></el-input>
    </el-form-item>
    <el-form-item label="数学">
      <el-input v-model="form.scoreInfo.mathGrade"></el-input>
    </el-form-item>
    <el-form-item label="英语">
      <el-input v-model="form.scoreInfo.englishGrade"></el-input>
    </el-form-item>
    <el-form-item label="物理">
      <el-input v-model="form.scoreInfo.physicsGrade"></el-input>
    </el-form-item>
    <el-form-item label="化学">
      <el-input v-model="form.scoreInfo.chemistryGrade"></el-input>
    </el-form-item>
    <el-form-item label="生物">
      <el-input v-model="form.scoreInfo.biologyGrade"></el-input>
    </el-form-item>
    <el-form-item label="历史">
      <el-input v-model="form.scoreInfo.historyGrade"></el-input>
    </el-form-item>
    <el-form-item label="地理">
      <el-input v-model="form.scoreInfo.geographyGrade"></el-input>
    </el-form-item>
    <el-form-item label="政治">
      <el-input v-model="form.scoreInfo.politicsGrade"></el-input>
    </el-form-item>
    <el-form-item label="专业课">
      <el-input v-model="form.scoreInfo.majorGrade"></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
import { commitUserInfo } from '@/api/user';

export default {
  name: 'UserInfoForm',
  data() {
    return {
      form: {
        userName: '',
        school: '',
        sex: null,
        studentType: null,
        scoreInfo: {
          cultureGrade: '', // 文化课
          biologyGrade: '', // 生物
          chemistryGrade: '', // 化学
          englishGrade: '', // 英语
          geographyGrade: '', // 地理
          historyGrade: '', // 历史
          languageGrade: '', // 语文
          majorGrade: '', // 专业
          mathGrade: '', // 数学
          physicsGrade: '', // 物理
          politicsGrade: '', // 政治,
          userId: '',
        },
      },
      rules: {},
    };
  },
  methods: {
    submitForm(id) {
      const params = {
        userId: id,
        source: 1, // pc 端
        ...this.form,
      };
      params.scoreInfo.userId = id;
      commitUserInfo(params).then((res) => {
        if (res.code === 0) {
          this.$message.messageSuccess('基本信息提交成功');
          localStorage.setItem('user', JSON.stringify(params));
          this.$emit('submitSuccess');
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  display: flex;
  flex-wrap: wrap;
  height: 350px;
  overflow-y: auto;

  .el-form-item {
    margin-bottom: 10px;
    width: 45%;
  }
}

.form-p {
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
  background-color: #efefefef;
  height: 30px;
  line-height: 30px;
  font-weight: bold;
  font-size: 16px;
}
</style>
