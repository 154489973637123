<template>
  <layout>
    <input-form class="register-form" mainTitle="注册">
      <div>
        <input-item
          ref="phone"
          inputPlaceholder="请输入手机号"
          :input-append-operation="`${canSentSms ? '发送验证码' : second }`"
          @operationHandler="sendValidCode"
        />
        <input-item
          ref="validCode"
          inputPlaceholder="请输入验证码"
          :inputPrependIcon="validCodeIcon"
          :inputShowAppend="false"
        />
        <input-item
          ref="password"
          inputPlaceholder="请输入账户密码"
          :inputShowPassword="true"
          :inputPrependIcon="passwordIcon"
          :inputShowAppend="false"
        ></input-item>
        <el-checkbox class="read" v-model="read" @change="openInstruction">
          <span style="color: rgba(128, 128, 128,0.8)">我已阅读</span>
        </el-checkbox>
        <span class="read-book" @click="openInstruction">用户需知</span>
      </div>
      <el-button class="register-btn" type="primary" @click="handleRegister">注册</el-button>
      <p class="go-login-btn" @click="toLogin">已有账号，马上登录</p>
    </input-form>
    <cap :is-show="validCodeShow" @showReceipt="validResult" />
    <user-info-dialog
      ref="userInfoDialog"
      :baseDialogVisible="userInfoIialogVisible"
      baseDialogWidth="50%"
      @closeDialog="closeUserInfoDialog"
    >
      <span slot="dialog-title">个人信息</span>
      <div class="form">
        <user-info-form ref="userForm" @submitSuccess="submitUserInfoSuccess" />
      </div>
      <span slot="dialog-footer">
        <el-button type="primary" style="width: 240px" @click="submitUserInfo">提交</el-button>
      </span>
    </user-info-dialog>
    <user-info-dialog
      baseDialogWidth="80%"
      class="instruction"
      :baseDialogShowClose="flagFalse"
      :baseDialogShowFoot="flagTrue"
      :base-dialog-visible="instructionsDialogVisible"
      :base-dialog-close-on-click-modal="flagFalse"
      @readed="readedFun"
      @closeDialog="closeInstructionDialog"
    >
      <span slot="dialog-title">用户需知</span>
      <div id="instruction-content">
      </div>
    </user-info-dialog>
  </layout>
</template>

<script>
import RegExp from '@/utils/regExp';
import * as ApiRegister from '@/api/login';
import UserInfoDialog from '@/components/BaseDialog/index.vue';
import UserInfoForm from './components/UserInfoForm.vue';
import Cap from './components/Cap.vue';
import Layout from './components/LoginLayout.vue';
import InputForm from './components/InputForm.vue';
import InputItem from './components/InputItem.vue';

const globalValidCodeIcon = require('@/assets/images/icon_valid_code@3x.png');
const globalPasswordIcon = require('@/assets/images/icon_password@3x.png');

export default {
  name: 'Register',
  components: {
    Layout,
    InputForm,
    InputItem,
    Cap,
    UserInfoDialog,
    UserInfoForm,
  },
  data() {
    return {
      flagTrue: true,
      flagFalse: false,
      knownTest: '',
      canSentSms: true,
      second: 60,
      instructionsDialogVisible: false,
      userInfoIialogVisible: false,
      validCodeShow: false,
      validCodeIcon: globalValidCodeIcon,
      passwordIcon: globalPasswordIcon,
      read: false,
      registerForm: {
        phone: '',
      },
    };
  },
  mounted() {
    window.addEventListener('keyup', this.handleKeyUp);
    this.getUserKnown();
  },
  destoryed() {
    window.removeEventListener('keyup', this.handleKeyUp);
  },
  methods: {
    handleKeyUp(key) {
      if (key.keyCode === 13) {
        this.handleRegister();
      }
    },
    openInstruction() {
      this.instructionsDialogVisible = true;
      this.$nextTick(() => {
        const content = document.getElementById('instruction-content');
        content.innerHTML = this.knownTest;
      }, 200);
    },
    closeInstructionDialog() {
      this.instructionsDialogVisible = false;
    },
    submitUserInfoSuccess() {
      this.userInfoIialogVisible = false;
      this.$router.push('/login');
    },
    submitUserInfo() {
      const userId = sessionStorage.getItem('current_userId');
      this.$refs.userForm.submitForm(userId);
    },
    closeUserInfoDialog() {
      this.userInfoIialogVisible = false;
    },
    toLogin() {
      this.$router.push('/login');
    },
    // handleTip() {
    //   console.info(this.read);
    //   this.read = !this.read;
    //   if (!this.read) {
    //     this.$message.messageError('请先阅读用户需知，并同意');
    //   }
    // },
    handleRegister() {
      localStorage.removeItem('user');
      if (!this.$refs.phone.getInputValue()) {
        this.$message.messageError('请填写手机号');
        return;
      }
      if (!this.$refs.validCode.getInputValue()) {
        this.$message.messageError('请填写验证码');
        return;
      }
      if (!this.$refs.password.getInputValue()) {
        this.$message.messageError('请填写密码');
        return;
      }
      if (this.$route.path === '/register' && !this.read) {
        this.$message.messageError('请先阅读用户需知，并同意');
        return;
      }
      const params = {
        phone: this.$refs.phone.getInputValue(),
        smCode: this.$refs.validCode.getInputValue(),
        password: this.$refs.password.getInputValue(),
        scene: 2,
        source: 1,
      };
      ApiRegister.Register(params).then((res) => {
        if (res.code === 0) {
          this.$message.messageSuccess('注册成功', 500);
          sessionStorage.setItem('current_userId', res.data.userId);
          const loginParam = {
            phone: this.$refs.phone.getInputValue(),
            password: this.$refs.password.getInputValue(),
            source: 1,
          };
          ApiRegister.LoginByPwd(loginParam).then((temp) => {
            if (temp.code === 0) {
              sessionStorage.setItem('sessionId', temp.data.sessionId);
              const loginData = {
                token: 'hasLogin',
                userId: res.data.userId,
              };
              this.$store.dispatch('login', loginData);
              localStorage.setItem('user', JSON.stringify(temp.data));
              if (!temp.data.userName) { // 用户还没提交用户注册信息
                this.$router.push('/userInfo');
              } else {
                this.$router.push('/index');
              }
            } else {
              this.$message.messageError(res.message);
            }
          });
        } else {
          this.$message.messageError(res.message);
        }
      });
    },
    sendValidCode() {
      if (!this.canSentSms) return;
      const phone = this.$refs.phone.getInputValue();
      if (!RegExp.phone.test(phone)) {
        this.$message.messageError('请输入正确的手机号');
        return;
      }
      this.validCodeShow = true;
    },
    validResult(val) {
      if (val) {
        this.validCodeShow = false;
        const params = {
          phone: this.$refs.phone.getInputValue(),
          scene: 2,
          source: 1,
        };
        ApiRegister.sendSms(params).then((res) => {
          if (res.code === 0) {
            this.$message.messageSuccess('验证码已发送');
            this.timeDown();
          } else {
            this.$message.messageError(res.message);
          }
        });
      }
    },
    timeDown() {
      this.canSentSms = false;
      const result = setInterval(() => {
        this.second -= 1;
        if (this.second <= 0) {
          clearInterval(result);
          this.second = 60;
          this.canSentSms = true;
        }
      }, 1000);
    },
    getUserKnown() {
      ApiRegister.getUserKnown().then((res) => {
        if (res.code === 0) {
          this.knownTest = res.data.content;
        }
      });
    },
    readedFun(val) {
      this.instructionsDialogVisible = false;
      console.info(val);
      if (val) {
        this.read = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.register-form {
  .input-item {
    width: 80%;
    margin: 20px auto;

    /deep/ .input-prepend-icon {
      width: 24px;
      height: 24px;
    }

    /deep/ .el-input-group__prepend {
      padding: 0 10px;
      background-color: transparent;
      border: unset;
    }

    /deep/ .el-input-group__append {
      padding: 0 12px;
      font-size: 12px;
      background-color: #409eff;
      color: #ffffff;
    }

    &:nth-child(3) {
      margin-bottom: 0;
    }
  }

  .read {
    margin: 10px 2px;

    /deep/ .el-checkbox__label {
      font-size: 12px;
      color: #409EFF;
    }
  }

  .read-book {
    font-size: 12px;
    color: #409EFF;
  }

  .read-book:hover {
    cursor: pointer;
    color: #f00;
  }

  .register-btn {
    width: 80%;
  }

  .go-login-btn {
    font-size: 12px;
    color: #409EFF;

    &:hover {
      cursor: pointer;
    }
  }
}
.instruction {
  /deep/ .el-dialog__body {
    padding: 10px 20px;
    text-align: left;
  }
}
</style>
